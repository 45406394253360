
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../jjsRegistration/images/label.png"
const FailedRedirection = () => {
  const navigate = useNavigate()
  useEffect(() => {
    localStorage.removeItem('barcode');

  }, [])
  return (
    <div className="wrapper">
      <div className="image-holder">
        <img src={logo} alt="" />
      </div>
      <div className="form-inner">
        <form>
          <div className="form-header">
            <h3>Registration Online for the show</h3>
          </div>
          <section id="wizard-p-1" role="tabpanel" aria-labelledby="wizard-h-1" className="body current" aria-hidden="false">
            <div className="form-row" style={{ display: 'flex', justifyContent: 'center' }}>
              <div className="card shadow">
                <div className="card-body text-center" style={{ padding: '30px 0px 10px' }}>
                  <h1 className="card-title mb-4" style={{ color: '#000', fontSize: '28px', fontWeight: 500 }}>
                    Payment Failed
                  </h1>
                  <div className="checkmark-container">
                    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 24 24" fill="none" stroke="maroon" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="bi bi-x-circle">
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M10 10l4 4m0-4l-4 4"></path>
                    </svg>
                  </div>
                  <p className="card-text mt-4 sec-text">Your payment could not be processed.</p>
                </div>
              </div>
            </div>
            <div className="form-row"></div>
            <div className="container">
              <div className="card bg-light p-4 text-center m-2">
                <h3 className="card-title"></h3>
                <p className="card-text">
                  <h4 style={{ color: '#000', textAlign: 'center' }} onClick={() => navigate("/")}>Back to Home</h4>
                </p>
              </div>
            </div>
          </section>
        </form>
      </div>
    </div>
  );
};

export default FailedRedirection;
