import React, { useEffect, useState } from "react";
import "./css/style.css"; // Assuming you have a CSS file for styling
import logo from "./images/label.png";
import Page2 from "./Page2";
import Page3 from "./Page3";
import Page4 from "./Page4";
import axios from "axios";
import { Descriptions, message, notification } from "antd";
import Otp from "./otp";
import PaymentPage from "../VisitorRegistration/PaymentPage";
import PaymentDetail from "../VisitorRegistration/PaymentDetail";
import PrintPage from "../VisitorRegistration/PrintPage";
const Index = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
  });
  const [errors, setErrors] = useState({ name: "", contact: "", email: "" });
  const [encrypted_data, setEncrypted_data] = useState("");
  const [shareBarcode, setShareBarcode] = useState(null);
  const [country, setCountry] = useState('India');

  const countries = [
    "Select",

    "Afghanistan",
    "Åland Islands",
    "Albania",
    "Algeria",
    "American Samoa",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarctica",
    "Antigua and Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia and Herzegovina",
    "Botswana",
    "Bouvet Island",
    "Brazil",
    "British Indian Ocean Territory",
    "Brunei Darussalam",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Canada",
    "Cape Verde",
    "Cayman Islands",
    "Central African Republic",
    "Chad",
    "Chile",
    "China",
    "Christmas Island",
    "Cocos (Keeling) Islands",
    "Colombia",
    "Comoros",
    "Congo",
    "Congo, The Democratic Republic of The",
    "Cook Islands",
    "Costa Rica",
    "Cote D'ivoire",
    "Croatia",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Eritrea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands (Malvinas)",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Guiana",
    "French Polynesia",
    "French Southern Territories",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-bissau",
    "Guyana",
    "Haiti",
    "Heard Island and Mcdonald Islands",
    "Holy See (Vatican City State)",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran, Islamic Republic of",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kiribati",
    "Korea, Democratic People's Republic of",
    "Korea, Republic of",
    "Kuwait",
    "Kyrgyzstan",
    "Lao People's Democratic Republic",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libyan Arab Jamahiriya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macao",
    "Macedonia, The Former Yugoslav Republic of",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Marshall Islands",
    "Martinique",
    "Mauritania",
    "Mauritius",
    "Mayotte",
    "Mexico",
    "Micronesia, Federated States of",
    "Moldova, Republic of",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Niue",
    "Norfolk Island",
    "Northern Mariana Islands",
    "Norway",
    "Oman",
    "Pakistan",
    "Palau",
    "Palestinian Territory, Occupied",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Pitcairn",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russian Federation",
    "Rwanda",
    "Saint Helena",
    "Saint Kitts and Nevis",
    "Saint Lucia",
    "Saint Pierre and Miquelon",
    "Saint Vincent and The Grenadines",
    "Samoa",
    "San Marino",
    "Sao Tome and Principe",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "Solomon Islands",
    "Somalia",
    "South Africa",
    "South Georgia and The South Sandwich Islands",
    "Spain",
    "Sri Lanka",
    "Sudan",
    "Suriname",
    "Svalbard and Jan Mayen",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syrian Arab Republic",
    "Taiwan, Province of China",
    "Tajikistan",
    "Tanzania, United Republic of",
    "Thailand",
    "Timor-leste",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad and Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks and Caicos Islands",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "United States",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Uzbekistan",
    "Vanuatu",
    "Venezuela",
    "Viet Nam",
    "Virgin Islands, British",
    "Virgin Islands, U.S.",
    "Wallis and Futuna",
    "Western Sahara",
    "Yemen",
    "Zambia",
    "Zimbabwe",
  ];

  // Transforming the list into the required format
  const countryArray = countries.map((country) => ({
    value: country,
    label: country,
  }));
  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Prevent non-numeric input in contact field
    if (name === "contact") {
      if (!/^\d*$/.test(value)) return; // Allow only digits in the contact field
    }

    // Prevent non-alphabetic input in name field
    if (name === "name") {
      if (!/^[A-Za-z\s]*$/.test(value)) return; // Allow only alphabets and spaces in the name field
    }

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validateForm = () => {
    let isValid = true;
    const newErrors = { name: "", contact: "" };

    // Check if the name contains only alphabetic characters
    if (!formData.name || !/^[A-Za-z\s]+$/.test(formData.name)) {
      newErrors.name = "Name should contain only alphabetic characters.";
      isValid = false;
    }

    // Check if the contact is numeric and exactly 10 digits
    if (!formData.contact || !/^\d{10}$/.test(formData.contact)) {
      newErrors.contact = "Mobile number should contain exactly 10 digits.";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (country === "Select") {
      notification.error({
        message: "country Required",
        descriptions: "",
      });
      return;
    }
    if (validateForm()) {
      // Submit the form or perform other actions
      const sendData = new FormData();
      sendData.append("contact", formData.contact);
      sendData.append("name", formData.name);
      sendData.append("email", formData.email);

      sendData.append("country", country);

      axios
        .post(
          `${process.env.REACT_APP_BSMPRO_END_POINT}/api/visitor/v2/generate-otp`,
          sendData
        )
        .then((resData) => {
          console.log("hello student", resData.data);
          console.log("Form submitted!");
          setStep(step + 1);
          setShareBarcode(resData.data.barcode);
          // localStorage.setItem("barcode", resData.data.barcode);
          message.success("Otp send");
        });
    } else {
      message.error("fill valid data");
    }
  };

  useEffect(() => {
    if (localStorage.getItem("barcode")) {
      const formData = new FormData();
      formData.append("barcode", localStorage.getItem("barcode"));

      axios
        .post(
          `${process.env.REACT_APP_BSMPRO_END_POINT}/api/visitor/v2/verify-otp`,
          formData
        )
        .then((resData) => {
          console.log("hello i am here vistior", resData.data.visitor[0].step);
          setStep(resData?.data?.visitor[0]?.step || 1);
        });
    }
  }, []);
  useEffect(() => {
    console.log("step", step);
  }, [step]);

  return (
    <div>
      {step === 1 && (
        <div className="wrapper">
          <div className="image-holder">
            <img src={logo} alt="Show Logo" />
          </div>
          <div className="form-inner">
            <div className="form-header">
              <h3>Register Here for the Show</h3>
            </div>
            <div className="form-header">
              <ul>
                <li className={step >= 1 ? "filled" : "notfilled"}>1</li>
                <li className={step >= 2 ? "filled" : "notfilled"}>2</li>
                <li className={step >= 3 ? "filled" : "notfilled"}>3</li>
                <li className={step >= 4 ? "filled" : "notfilled"}>4</li>
                {/* <li className={step >= 5 ? "filled" : ""}>4</li> */}
              </ul>
            </div>
            {/* <h6 style={{ color: "#fff" }} className="blinking-text">
              If you want to be part of Pink Club, then please choose Gems &
              Jewellery.
            </h6> */}

            <form onSubmit={handleSubmit}>
              <div className="step-1">
                <div className="form-group">
                  <label htmlFor="name">Full Name:</label>
                  <input
                    name="name"
                    type="text"
                    className="form-control"
                    required
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {errors.name && <p className="error-text">{errors.name}</p>}
                </div>
                <div className="form-group">
                  <label htmlFor="contact">Mobile Number:</label>
                  <input
                    type="tel"
                    required
                    name="contact"
                    className="form-control"
                    value={formData.contact}
                    maxLength={10}
                    onChange={handleInputChange}
                  />
                  {errors.contact && (
                    <p className="error-text">{errors.contact}</p>
                  )}
                </div>
                <div className="form-row" style={{ marginBottom: "3.4vh" }}>
                  <label htmlFor="category">Country:</label>
                  <div className="form-holder">
                    <select
                      name="category"
                      className="form-control"
                      value={country}
                      onChange={(e) => setCountry(e.target.value)}
                      required
                    >
                      {countryArray.map((item, idex) => (
                        <option value={item.value}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </div>

                {country !== "India" && (
                  <div className="form-group">
                    <label htmlFor="email">Email:</label>
                    <input
                      name="email"
                      type="email"
                      className="form-control"
                      required
                      value={formData.email}
                      onChange={handleInputChange}
                    />
                    {errors.email && (
                      <p classemail="error-text">{errors.email}</p>
                    )}
                  </div>
                )}
              </div>

              <button type="submit" className="btn">
                Continue
              </button>
            </form>
          </div>
        </div>
      )}

      {step === 2 && (
        <Otp step={step} setStep={setStep} shareBarcode={shareBarcode} />
      )}
      {step === 3 && <Page2 step={step} setStep={setStep} />}

      {step === 4 && <Page3 step={step} setStep={setStep} />}
      {step === 5 && (
        <Page4
          step={step}
          setStep={setStep}
          encrypted_data={encrypted_data}
          setEncrypted_data={setEncrypted_data}
        />
      )}
      {step === 7 && (
        <>
          <PaymentPage
            setDataShow={setStep}
            dataShow={step}
            encrypted_data={encrypted_data}
            setEncrypted_data={setEncrypted_data}
          />
        </>
      )}
      {step === 8 && (
        <>
          <PaymentDetail
            setDataShow={setStep}
            dataShow={step}
            encrypted_data={encrypted_data}
            setEncrypted_data={setEncrypted_data}
          />
        </>
      )}

      {step === 9 && (
        <>
          <PrintPage
            barcode={localStorage.getItem("barcode")}
            text={"Your registration is complete at jjsjaipur.com."}
          />
          {/* <div className="container mt-5">
              <div className="card bg-light p-4 text-center m-5">
                <h1 className="card-title">Thank you!</h1>
                <p className="card-text">
                  Your registration is complete at jjsjaipur.com.
                </p>
              </div>
            </div> */}
        </>
      )}
    </div>
  );
};

export default Index;
