import React, { useEffect } from 'react';

const RegistrationSuccessPage = () => {
  const pageStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f8f8',
    fontFamily: 'Arial, sans-serif'
  };

  const cardStyle = {
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    padding: '40px',
    textAlign: 'center',
    maxWidth: '500px',
    width: '100%'
  };

  const iconStyle = {
    width: '100px',
    height: '100px',
    color: '#4CAF50',
    margin: '0 auto 20px'
  };

  useEffect(() => {
    localStorage.removeItem('barcode');
  }, []);

  return (
    <div style={pageStyle}>
      <div style={cardStyle}>
        <svg 
          style={iconStyle} 
          xmlns="http://www.w3.org/2000/svg" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <h1 style={{color: '#333', marginBottom: '20px'}}>
          Registration Successful
        </h1>
        <p style={{color: '#666', marginBottom: '30px'}}>
          You have successfully registered for 
          <br />
          The December Show
        </p>
        <p style={{color: '#888', marginBottom: '30px'}}>
          19-22 DEC '25 | Jaipur Convention Centre
        </p>
        <button 
          onClick={() => window.location.href = '/'} 
          style={{
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            padding: '10px 20px',
            borderRadius: '5px',
            cursor: 'pointer',
            transition: 'background-color 0.3s ease'
          }}
        >
          Return to Home
        </button>
      </div>
    </div>
  );
};

export default RegistrationSuccessPage;
