import axios from "axios";
import React, { useEffect, useState } from "react";
import { message } from "antd";
import logo from "./images/label.png";

const Otp = ({ step, setStep, shareBarcode }) => {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [resendCount, setResendCount] = useState(0);
  const [timer, setTimer] = useState(59);
  const [isTimerRunning, setIsTimerRunning] = useState(true);
  const [isResendDisabled, setIsResendDisabled] = useState(false);
  const [resendMessage, setResendMessage] = useState("");

  useEffect(() => {
    let intervalId;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer === 1) {
            clearInterval(intervalId);
            setIsTimerRunning(false);
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isTimerRunning]);
  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return;

    setOtp([...otp.map((d, idx) => (idx === index ? element.value : d))]);

    // Focus on the next input
    if (element.nextSibling && element.value !== "") {
      element.nextSibling.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace") {
      // If the input is empty, focus on the previous input
      if (otp[index] === "" && e.target.previousSibling) {
        e.target.previousSibling.focus();
      }

      // Remove the character from the current input
      setOtp(otp.map((d, idx) => (idx === index ? "" : d)));
    }
  };

  useEffect(() => {
    if (Array.isArray(otp) && !otp.every((element) => element !== "")) {
      return;
    }
    console.log("verify Otp", otp);
    const formData = new FormData();
    formData.append("otp", otp.join(""));
    formData.append("barcode", shareBarcode);

    axios
      .post(
        `${process.env.REACT_APP_BSMPRO_END_POINT}/api/visitor/v2/verify-otp`,
        formData
      )
      .then((resData) => {
        console.log(resData.data, "response");
        // const userStringfyData = JSON.stringify(userData);

        // Encrypt the string using AES encryption
        localStorage.setItem("barcode", resData?.data?.visitor[0]?.barcode);
        setStep(resData.data.visitor[0].step);
      })
      .catch((error) => {
        message.error("Invalid Otp");
        setOtp(new Array(4).fill(""));
      });
  }, [otp]);

  const handleResendOTP = (e) => {
    if (resendCount < 3) {
      const formData = new FormData();
      formData.append(
        "barcode",
        localStorage.getItem("barcode") || shareBarcode
      );
      axios
        .post(
          `${process.env.REACT_APP_BSMPRO_END_POINT}/api/visitor/v2/re-send-otp`,
          formData
        )
        .then((resData) => {
          setResendCount((prevCount) => prevCount + 1);
          setTimer(59);
          setIsTimerRunning(true);
          message.success("Otp Send");
        });
    } else {
      setIsResendDisabled(true);
      setResendMessage(
        "You have reached your limit. Please try again after 10 minutes."
      );
      setTimeout(() => {
        setIsResendDisabled(false);
        setResendMessage("");
      }, 600000); // 10 minutes in milliseconds
    }
  };

  return (
    <div className="wrapper">
      <div className="image-holder">
        <img src={logo} alt="" />
      </div>
      <div className="form-inner">
        <form>
          <div className="form-header">
            <h3>Registration Online for the show</h3>
          </div>
          <div className="form-header">
            <ul>
              <li className="filled">1</li>
              <li className="filled">2</li>
              <li className="notfilled">3</li>
              <li className="notfilled">4</li>
              {/* <li className="filled">5</li> */}
            </ul>
          </div>

          <section>
            <div
              className="form-row"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="content">
                <h2>Enter your OTP</h2>
                {resendMessage && <span>{resendMessage}</span>}

                <div className="otp-input-box">
                  {otp &&
                    otp.map((data, index) => {
                      return (
                        <input
                          type="text"
                          name="otp"
                          className="otp-input"
                          maxLength="1"
                          key={index}
                          value={data}
                          onChange={(e) => handleOtpChange(e.target, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          onFocus={(e) => e.target.select()}
                          required
                        />
                      );
                    })}
                </div>
              </div>
            </div>
          </section>

          <div className="btn-box" style={{ justifyContent: "center" }}>
            <div className="d-flex">
              <button
                type="button"
                style={{ padding: "10px" }}
                className="btn btn-primary"
                onClick={handleResendOTP}
                disabled={isTimerRunning || isResendDisabled}
              >
                Resend OTP ({timer}s)
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Otp;
