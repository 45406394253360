import React from 'react';

const RegistrationFailurePage = () => {
  const pageStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f8f8',
    fontFamily: 'Arial, sans-serif'
  };

  const cardStyle = {
    backgroundColor: 'white',
    borderRadius: '12px',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    padding: '40px',
    textAlign: 'center',
    maxWidth: '500px',
    width: '100%'
  };

  const iconStyle = {
    width: '100px',
    height: '100px',
    color: '#FF6B6B',
    margin: '0 auto 20px'
  };

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px'
  };

  return (
    <div style={pageStyle}>
      <div style={cardStyle}>
        <svg 
          style={iconStyle} 
          xmlns="http://www.w3.org/2000/svg" 
          fill="none" 
          viewBox="0 0 24 24" 
          stroke="currentColor"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
          />
        </svg>
        <h1 style={{color: '#333', marginBottom: '20px'}}>
          Registration Failed
        </h1>
        <p style={{color: '#666', marginBottom: '30px'}}>
          We apologize, but your registration 
          <br />
          could not be processed
        </p>
        <p style={{color: '#888', marginBottom: '30px'}}>
          Please check your information and try again
        </p>
        <div style={buttonContainerStyle}>
          <button 
            onClick={() => window.location.href = '/register'} 
            style={{
              backgroundColor: '#007bff',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease'
            }}
          >
            Try Again
          </button>
          <button 
            onClick={() => window.location.href = '/'} 
            style={{
              backgroundColor: '#6c757d',
              color: 'white',
              border: 'none',
              padding: '10px 20px',
              borderRadius: '5px',
              cursor: 'pointer',
              transition: 'background-color 0.3s ease'
            }}
          >
            Return Home
          </button>
        </div>
      </div>
    </div>
  );
};

export default RegistrationFailurePage;
