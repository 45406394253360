import React, { useEffect, useState } from "react";
import "./css/style.css"; // Assuming you have a CSS file for styling
import axios from "axios";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import logo from "../jjsRegistration/images/label.png";

import { useNavigate } from "react-router-dom";
const Page4 = ({ step, setStep, encrypted_data, setEncrypted_data }) => {
  const navigate = useNavigate();
  const [payment, setPayment] = useState(false);
  const [paymentText, setPaymentText] = useState(null);
  const [agree, setAgree] = useState(true);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const formData = new FormData();

    formData.append("step", 5);
    // formData.append("city", city ? city : "");
    // formData.append("address", address ? address : "");
    // formData.append("pincode", pincode ? pincode : "");
    // formData.append("afree", pincode ? pincode : "");

    formData.append("barcode", localStorage.getItem("barcode"));
    formData.append("agree", agree);

    console.log("Submitting form data:", {
      step: 5,
      barcode: localStorage.getItem("barcode"),
      agree: agree,
    });

    axios
      .post(
        `${process.env.REACT_APP_BSMPRO_END_POINT}/api/visitor/v2/register-visitor`,
        formData
      )
      .then((resData) => {
        console.log("Response received:", resData.data);
        if (resData.data.payment_code) {
          setPayment(true);
          setPaymentText(resData.data.msg);
          // window.open(`https://jjs.samaksh.in/pay-now?order_id=${localStorage.getItem("barcode")}`);
          // setEncrypted_data(resData.data.payment_code);
        } else {
          // setDataShow(9);
          navigate(`/e-card/${localStorage.getItem("barcode")}`);
          setTimeout(() => {
            localStorage.removeItem("barcode");
            console.log("Barcode removed from localStorage");
          }, 1000);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err, "response");
      });
  };

  // const handleBlur = () => {
  //   setShowSpinner(true);
  //   if (!pincode) {
  //     return
  //   }
  //   axios
  //     .post(`${process.env.REACT_APP_API_END_POINT}/get-city-from-pincode`, {
  //       pincode,
  //     })
  //     .then((resData) => {
  //       setTimeout(() => {
  //         console.log("hello", resData?.data?.data)
  //         setCity(resData?.data?.data);
  //         setShowSpinner(false);
  //       }, 1000); // Set the spinner to false after 1 second
  //     });
  // };

  // useEffect(() => {
  //   if (pincode?.length === 6) {
  //     handleBlur();
  //   }
  // }, [pincode]);
  return (
    <div className="wrapper">
      <div className="">
        <Modal
          show={payment}
          onHide={() => setPayment(false)}
          animation={false}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>{paymentText}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="mx-auto">
            <>
              <a
                href={`https://jjs.samaksh.in/pay-now?order_id=${localStorage.getItem(
                  "barcode"
                )}`}
                // target="_blank"
                rel="noopener noreferrer"
                className="btn btn-primary"
              >
                Pay now
              </a>
            </>
          </Modal.Body>
        </Modal>
      </div>
      <div className="image-holder">
        <img src={logo} alt="" />
      </div>
      <div className="form-inner">
        <form action="" onSubmit={handleSubmit}>
          <div className="form-header">
            <h3>Registration Online for the show</h3>
          </div>
          <div className="form-header">
            <ul>
              <li className="filled">1</li>
              <li className="filled">2</li>
              <li className="filled">3</li>
              <li className="filled">4</li>
              {/* <li className="filled">5</li> */}
            </ul>
          </div>

          <section
            id="wizard-p-3"
            role="tabpanel"
            aria-labelledby="wizard-h-3"
            className="body current"
            aria-hidden="false"
            style={{}}
          >
            {/* <div className="checkbox-circle">
              <label>
                <input
                  name="term"
                  type="checkbox"
                  required
                  defaultChecked
                  onChange={(e) => setAgree(e.target.checked)}
                />
                I agree to all statements in{' '}
                <a href="term_conditions.html" target="_blank" rel="noopener noreferrer">
                  Terms &amp; Conditions
                </a>
                <span className="checkmark"></span>
              </label>
            </div> */}
          </section>
          <div className="btn-box">
            <>
              <a onClick={() => setStep(step - 1)} className="btn">
                Back
              </a>
              <button type="submit" className="btn">
                {" "}
                {loading ? (
                  <div class="spinner-border text-danger" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                ) : (
                  "Submit"
                )}
              </button>
            </>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Page4;
