import React, { useCallback, useEffect, useState } from "react";
import image_demo from "../images/print_demo.png";
import axios from "axios";
import html2canvas from "html2canvas";
import Barcode from "react-barcode";
import { useParams } from "react-router-dom";
import logo from "../jjsRegistration/images/label.png"
const PrintableContent = ({ barcode, text }) => {
  const [image, setImage] = useState("");
  const [name, setName] = useState("");
  const [city, setCity] = useState("");
  const [visitor, setVisitor] = useState("");
  // const [image, setImage] = useState("");
  const [showError, setShowError] = useState(false);
  const [loader, setLoader] = useState(false)
  const category_list = [
    // { label: "select category", value: "0" },
    { label: "Trade Visitor", value: "31" },
    { label: "Trade Member", value: "24" },
  ];
  const { bar_code } = useParams();
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    const formData = new FormData();
    if (bar_code) {
      formData.append("barcode", bar_code);
      console.log("helllo i am jhere ", bar_code);

    } else {
      formData.append("barcode", barcode);
      console.log("helllo i am jhere ", barcode);
    }

    axios
      .post(`https://jjs.samaksh.in/api/visitor/v2/verify-otp`, formData)
      .then((resData) => {
        const { photo, name, city, category_id, barcode } =
          resData.data.visitor[0];
        setImage(photo);
        setName(name);
        const filterDataCategory = category_list.filter(
          (item) => parseInt(item.value) === parseInt(category_id)
        );
        setVisitor(filterDataCategory[0] ? filterDataCategory[0]?.label : "");
        console.log("asdasdaaadad", filterDataCategory[0]?.label);
        setCity(city);

        setInputValue(barcode);
        //  setPincode(pincode);
        // Remove the barcode from localStorage after 1 second
        setTimeout(() => {
          localStorage.removeItem('barcode');
          console.log('Barcode removed from localStorage');
        }, 1000);
      })
      .catch((err) => {
        console.log("hello", err.message)
        setShowError(true);
      });
  }, []);

  const handleScreenshot = useCallback(() => {
    const body = document.body;
    setLoader(true)
    html2canvas(body).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const link = document.createElement("a");
      link.href = imgData;
      link.download = "screenshot.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
    setLoader(false)

  }, []);

  return (
    <>
      <div className="wrapper">
        <div className="image-holder">
          <img src={logo} alt="" />
        </div>
        <div className="form-inner">
          <div className="form-header">
            <h2 style={{ color: 'white' }}>{`Success`}</h2>
          </div>
          <form action="">
            <div className="form-header">
              <h3 style={{ color: 'white' }}>{text}</h3>
            </div>
            {showError ? <>
              <div className="container h-100 mt-5">
                <div className="row h-100 justify-content-center align-items-center">
                  <div >
                    <div className="card shadow">
                      <div className="card-body text-center">
                        <h2 className="card-title mb-4">
                          This Barcode does not exist!
                        </h2>
                        {/* <div className="checkmark-container">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="120"
                  height="120"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="green"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="bi bi-check-circle"
                >
                  <circle cx="12" cy="12" r="10" />
                  <path d="M9 12l2 2 4-4" />
                </svg>
              </div> */}
                        <p className="card-text mt-4">Jjs.jaipur.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </> : (
              <section id="wizard-p-1" role="tabpanel" aria-labelledby="wizard-h-1" className="body current" aria-hidden="false">
              <div className="form-row" style={{ display: 'flex', justifyContent: 'center' }}>
                <div id="printable-content" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <div className="imge-section">
                    <img src={image} style={{
                      margin: '0 auto',
                      position: 'absolute',
                      top: '160px',
                      height: '110px',
                      width: '100px',
                      left: '0px',
                      right: '0px',
                      objectFit: 'contain'
                    }} alt="" />
                    <div className="id-text" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'absolute', left: 0, right: 0, bottom: '120px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <h3 className="mt-1" style={{ fontSize: '12px', color: '#000' }}>{name}</h3>
                      </div>
                      <span style={{ fontSize: '12px', color: '#000' }}>{city}</span>
                      <div style={{ height: '10px' }}>
                        {inputValue && (
                          <Barcode
                            style={{ background: "#ffffff" }}
                            value={inputValue}
                            format="CODE128"
                            width={2}
                            height={35}
                            displayValue={true}
                          />
                        )}
                        <div className="text-center">
                          <h4 className="mt-3" style={{ color: '#000', fontSize: '20px', textAlign: 'center' }}>{visitor}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="form-row">
                <div className="container">
                  <div className="card bg-light p-4 text-center m-2">
                    <h3 className="card-title"></h3>
                    <p className="card-text">
                      <h4 style={{ color: '#000', textAlign: 'center', cursor: 'pointer' }} onClick={handleScreenshot}>{loader ? <div class="spinner-border text-danger" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div> : 'Take A Screenshot'}</h4>
                    </p>
                  </div>
                </div>
              </div>
            </section>)}
          </form>
        </div>
      </div>
    </>
  );
};

export default PrintableContent;
