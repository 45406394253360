import "./App.css";
import "./index.css";
import { React } from "react";
import { Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Header from "./components/superAdmin/dashboard/Header";
import Footer from "./components/superAdmin/footer/Footer";
import Default from "./routes/Default";
import { Suspense } from "react";
import { Spinner } from "react-bootstrap";
import ExhibitorDashboardNavbar from "./components/exhibitor/exhibitor_dashboard/ExhibitorDashboardNavbar";
import VisitorForm from "./components/HtmlMainPage/MainPage";
import InvitedVIPGuestList from "./components/exhibitor/SubAdminArea/InvitedVIPGuestList";
import AdminInvitedVIPGuestList from "./components/AdminVIP/AdminInvitedVIPGuestList";
import MainRoute from "./routes/MainRoute";

import SuperAdminAreaLogin from "./components/superAdmin/Settings/SuperAdminAreaLogin";
import AdminVIPLogin from "./components/AdminVIP/AdminVIPLogin";
import ExhibitorLogin from "./components/exhibitor/Form/ExhibitorLogin";
import ExhibitorStaff from "./components/visitorform/ExhibitorStaff";
import ExhibitorVip from "./components/visitorform/ExhibitorVip";
import ExhibitorOwner from "./components/visitorform/ExhibitorOwner";
import VendorOwner from "./components/visitorform/VendorOwner";
import VendorStaff from "./components/visitorform/VendorStaff";
import Wrong404 from "./404/Wrong404";
import FrontEndPage from "./components/HtmlMainPage/jjsRegistration/Index";
import PaymentPage from "./components/HtmlMainPage/VisitorRegistration/SuccessfulRedirection";

import HostedBuyerRegistation from "./components/HtmlMainPage/HostedBuyerResistration/JjsMainPage";
import HospitalityLogin from "./components/Hospitality/HospitalityLogin";
import CabServiceLogin from "./components/CabService/CarServiceLogin";
import FlightServicelogin from "./components/FlightService/FlightServiceLogin";
import SuccessfulRedirection from "./components/HtmlMainPage/VisitorRegistration/SuccessfulRedirection";
import FailedRedirection from "./components/HtmlMainPage/VisitorRegistration/FailedRedirection";
import PrintableContent from "./components/HtmlMainPage/VisitorRegistration/PrintableContent";
import BarcodeScanning from "./components/HtmlMainPage/BarcodeScanning"
import VisitorBarcodeScanning from "./components/HtmlMainPage/VisitorBarcodeScanning"
import VantaNetBackground from "./components/HtmlMainPage/VantaNetBackground"
import RegistrationSuccessPage from "./components/HtmlMainPage/jjsRegistration/Success";
import RegistrationFailurePage from "./components/HtmlMainPage/jjsRegistration/Failure";

// 
function App() {
  return (
    <>
      {/* <MainPage/> */}
      {/* <Suspense fallback={<Spinner animation="border" size="lg" />}> */}
      <Suspense
        fallback={
          <div className="d-flex justify-content-center align-items-center content-height">
            Loading... &nbsp; &nbsp;
            <Spinner animation="border" size="lg" />
          </div>
        }
      >
        <Routes>
          <Route path="/adminvip/login" element={<AdminVIPLogin />} />
          <Route
            path="/payment-successful-redirection"
            exact={true}
            element={<SuccessfulRedirection />}
          />
          <Route
            path="/payment-failed-redirection"
            exact={true}
            element={<FailedRedirection />}
          />
          <Route path="/superadmin/login" element={<SuperAdminAreaLogin />} />
          <Route path="/exhibitor/login" element={<ExhibitorLogin />} />
          <Route path="/hospitality/login" element={<HospitalityLogin />} />
          <Route path="/cab-service/login" element={<CabServiceLogin />} />
          <Route
            path="/flight-service/login"
            element={<FlightServicelogin />}
          />

          <Route path="/404" element={<Wrong404 />} />
          {/* <Route path="/404" element={<PaymentPage />} /> */}
          <Route path="/e-card/:bar_code" element={<PrintableContent text={"Please find your card below to visit at JJS"}/>} />

          <Route path="/success" element={<RegistrationSuccessPage />} />
          <Route path="/cancel" element={<RegistrationFailurePage />} />

          <Route path="/" element={<FrontEndPage />} />
          {/* <Route path="/" element={<FristPage />} /> */}
          <Route path="/barcode-scanning" element={<BarcodeScanning />} />
          <Route path="/barcode-scanning/tv" element={<VisitorBarcodeScanning />} />
          <Route path="/barcode-scanning/v" element={<VisitorBarcodeScanning />} />



          <Route
            path="/hosted-buyer-registration"
            element={<HostedBuyerRegistation />}
          />

          {/* <Route
            path="/exhibitor-staff-registration"
            element={<ExhibitorStaff />}
          /> */}
          <Route path="/registration" element={<VisitorForm />} />
          {/* <Route
            path="/exhibitor-vip-registration"
            element={<ExhibitorVip />}
          /> */}
          {/* <Route
            path="/exhibitor-owner-registration"
            element={<ExhibitorOwner />}
          /> */}
          <Route
            path="/vendor-owner-registration/:barcode"
            element={<VendorOwner />}
          />
          <Route
            path="/vendor-staff-registration/:barcode"
            element={<VendorStaff />}
          />
          <Route path="/vip-guest-registration" element={<ExhibitorVip />} />
          <Route path="/404" element={<Wrong404 />} />
          <Route path="*" element={<Default />} />
        </Routes>
      </Suspense>
    </>
  );
}

export default App;
